import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Rate from './components/Rate.js';
import Login from './components/Login'; 
import Home from './components/Home.js';
import Report from './components/Report';
import Thanks from './components/Thanks';
import Addrestaurant from './components/Addrestaurant';
import Addfood from './components/Addfood';
import Restaurant from './components/Restaurant.js';
import SignUpPage from './components/SignUpPage.js';
import Editmenu from './components/Editmenu.js';
import Editrestaurant from './components/Editrestaurant.js';
import ForgotPasswordPage from './components/Forgotpass.js';
import Asignres from './components/Asignes.js';
import Map from './components/Map.js';
import Promotions from './components/Promotions.js';

function App() {
  const [userData, setUserData] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false); // You might not need this state anymore if you're not using it elsewhere
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({ city: '', rating: 0, surveyCount:0, petFriendly: false });
  const [selectedCategory, setSelectedCategory] = useState('');
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/getUser`, {
          credentials: 'include' // Ensures cookies are sent with the request, which is essential for session-based authentication
        });
        if (response.ok) {
          const data = await response.json();
          setUserData({
            username: data.username,
            restaurantid: data.restaurantid,
            userid: data.id
          });
        } else {
          // Handle scenarios where the user is not logged in or the session has expired
          // You can set userData to an empty object or a specific "logged out" state
          setUserData({});
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setUserData({});
      }
    };

    // Call getUserData on every component mount
    getUserData();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const handleLoginStatusChange = (status) => {
    setIsLoggedIn(status);
  }
  const clearFilters = () => {
    setSearchTerm('');
    setSelectedCategory('');
    setFilters({ city: '', rating: 0, priceRange:null, surveyCount:0, petFriendly: false });
  };

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home userData={userData} onSearch={handleSearch} searchTerm={searchTerm} filters={filters} setFilters={setFilters} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onClearFilters={clearFilters} />} />
        <Route path='/Home' element={<Home userData={userData} onSearch={handleSearch} searchTerm={searchTerm} filters={filters} setFilters={setFilters} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onClearFilters={clearFilters} />} />
        <Route path='/Map' element={<Map userData={userData} onSearch={handleSearch} searchTerm={searchTerm} filters={filters} setFilters={setFilters} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onClearFilters={clearFilters} />} />
        <Route path='/Rate' element={<Rate userData={userData} />} />
        <Route path='/Add' element={<Addrestaurant userData={userData} />} />
        <Route path='/Asignres' element={<Asignres userData={userData} />} />
        <Route path='/Editmenu' element={<Editmenu userData={userData} />} />
        <Route path='/Editrestaurant' element={<Editrestaurant userData={userData} />} />
        <Route path='/Menu' element={<Addfood userData={userData} />} />
        <Route path='/Login' element={<Login onLoginStatusChange={handleLoginStatusChange} />} />
        <Route path='/Report' element={<Report userData={userData} />} />
        <Route path='/Thanks' element={<Thanks userData={userData} />} />
        <Route path='/Resetpass' element={<ForgotPasswordPage userData={userData} />} />
        <Route path='/Restaurant' element={<Restaurant userData={userData} onSearch={handleSearch} searchTerm={searchTerm} filters={filters} setFilters={setFilters} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onClearFilters={clearFilters} />} />
        <Route path='/Signup' element={<SignUpPage userData={userData} onLoginStatusChange={handleLoginStatusChange} />} />
        <Route path='/Promos' element={<Promotions/>} />
      </Routes>
    </Router>
  );
}

export default App;
